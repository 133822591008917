import { createSlice } from '@reduxjs/toolkit';
// utils
import { IMessageState } from '../../@types/message';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------
const initialState: IMessageState = {
  isLoading: false,
  error: null,
  messages: [],
  message: null,
};

const slice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MESSAGES
    getMessagesSuccess(state, action) {
      state.isLoading = false;
      state.messages = action.payload;
    },

    // DELETE MESSAGE
    deleteMessageSuccess(state, action) {
      state.isLoading = false;
      state.messages = state.messages.filter((message) => message.id !== action.payload.id);
    },

    // GET MESSAGE
    getMessageSuccess(state, action) {
      state.isLoading = false;
      state.message = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
// Actions
export const {
  startLoading,
  stopLoading,
  hasError,
  getMessagesSuccess,
  deleteMessageSuccess,
  getMessageSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getMessages() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get('/messages');
      dispatch(getMessagesSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMessage(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/messages/${id}`);
      dispatch(getMessageSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteMessage(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.delete(`/messages/${id}`);
      dispatch(deleteMessageSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function sendMessage(id: string, data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      await axios.post(`/messages/reply/${id}`, data);
      dispatch(getMessages());
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}
