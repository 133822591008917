import { createSlice } from '@reduxjs/toolkit';
// utils
import { INeighborhoodState } from '../../@types/neighborhood';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------
const initialState: INeighborhoodState = {
  isLoading: false,
  error: null,
  neighborhoods: [],
  neighborhood: null,
};

const slice = createSlice({
  name: 'neighborhood',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET NEIGHBORhoodS
    getNeighborhoodsSuccess(state, action) {
      state.isLoading = false;
      state.neighborhoods = action.payload;
    },

    // CREATE NEIGHBORhood
    createNeighborhoodSuccess(state, action) {
      state.isLoading = false;
      state.neighborhoods = [...state.neighborhoods, action.payload];
    },

    // UPDATE NEIGHBORhood
    updateNeighborhoodSuccess(state, action) {
      state.isLoading = false;
      state.neighborhoods = state.neighborhoods.map((neighborhood) =>
        neighborhood.id === action.payload.id ? action.payload : neighborhood
      );
    },

    // DELETE NEIGHBORhood
    deleteNeighborhoodSuccess(state, action) {
      state.isLoading = false;
      state.neighborhoods = state.neighborhoods.filter(
        (neighborhood) => neighborhood.id !== action.payload.id
      );
    },
  },
});

// ----------------------------------------------------------------------

export const {
  startLoading,
  stopLoading,
  hasError,
  getNeighborhoodsSuccess,
  createNeighborhoodSuccess,
  updateNeighborhoodSuccess,
  deleteNeighborhoodSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getNeighborhoods() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get('/neighborhoods');
      dispatch(getNeighborhoodsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createNeighborhood(data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.post('/neighborhoods', data);
      dispatch(createNeighborhoodSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateNeighborhood(id: string, data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.patch(`/neighborhoods/${id}`, data);
      dispatch(updateNeighborhoodSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteNeighborhood(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.delete(`/neighborhoods/${id}`);
      dispatch(deleteNeighborhoodSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export default slice.reducer;
