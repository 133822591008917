import { createSlice } from '@reduxjs/toolkit';
// utils
import { IPostState } from '../../@types/post';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------
const initialState: IPostState = {
  isLoading: false,
  error: null,
  posts: [],
  post: null,
};

const slice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // UPDATE POST
    updatePostSuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    // CREATE POST

    createPostSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // DELETE POST
    deletePostSuccess(state, action) {
      state.isLoading = false;
      state.posts = state.posts.filter((post) => post.id !== action.payload);
    },

    // GET POST
    getPostSuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Actions
export const {
  startLoading,
  stopLoading,
  hasError,
  getPostsSuccess,
  updatePostSuccess,
  createPostSuccess,
  deletePostSuccess,
  getPostSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getPosts() {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get('/posts');
      dispatch(getPostsSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function getPost(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.get(`/posts/${id}`);
      dispatch(getPostSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function createPost(data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.post('/posts', data);
      dispatch(createPostSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function updatePost(data: any) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.put(`/posts/${data.id}`, data);
      dispatch(updatePostSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export function deletePost(id: string) {
  return async (dispatch: any) => {
    dispatch(startLoading());
    try {
      const response = await axios.delete(`/posts/${id}`);
      dispatch(deletePostSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
